<template>
  <div class="portfolio-card">
    <router-link :to="`/portfolios/${workId}`" class="cover-image">
      <img :src="coverImage" />
      <div class="mask">
        <span>View More</span>
      </div>
    </router-link>
    <section class="work-info">
      <p class="work-m">{{ nameInMandarin }}</p>
      <p class="work-en">{{ nameInEnglish }}</p>
      <p class="author">
        {{ author ? `${author.name} ${author.name_en}` : "" }}
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "PortfolioCard",
  props: {
    coverImage: String,
    nameInMandarin: String,
    nameInEnglish: String,
    author: Object,
    workId: Number,
  },
  setup(props) {
    const { coverImage, nameInMandarin, nameInEnglish, author, workId } = props;
    return {
      coverImage,
      nameInMandarin,
      nameInEnglish,
      author,
      workId,
    };
  },
};
</script>

<style lang="scss" scoped>
.portfolio-card {
  @include RWD($tablet) {
    margin-bottom: 38px;
  }

  .cover-image {
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: calc((100% * 9) / 16);

    &:hover {
      .mask {
        display: flex;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .mask {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);

      span {
        color: $white;
        font-family: "Red Rose";
        font-size: 20px;
        font-weight: 400;
      }
    }
  }

  .work-info {
    margin-top: 35px;

    @include RWD($mediumLaptop) {
      margin-top: 20px;
    }

    @include RWD($tablet) {
      margin-top: 15px;
      padding: 0 12px;
    }

    p {
      color: $white;

      &.work-m {
        font-family: "Zpix";
        font-size: 20px;

        @include RWD($mediumLaptop) {
          font-size: 14px;
          margin-bottom: 3px;
        }

        @include RWD($tablet) {
          font-size: 16px;
        }
      }

      &.author {
        font-family: "Zpix";
        font-size: 16px;
        opacity: 0.7;

        @include RWD($mediumLaptop) {
          font-size: 14px;
        }

        @include RWD($tablet) {
          font-size: 14px;
        }
      }

      &.work-en {
        font-family: "Red Rose";
        font-size: 20px;
        line-height: 25px;

        @include RWD($mediumLaptop) {
          font-size: 14px;
          line-height: 18px;
        }

        @include RWD($tablet) {
          font-size: 16px;
        }
      }
    }
  }
}
</style>