<template>
  <div class="selector">
    <input
      @click="setShowDropdown"
      :placeholder="placeholder"
      :value="value"
      type="text"
    />
    <ul v-show="showDropdown" class="panel">
      <li v-for="(option, index) in options" :key="index">
        <button @click="handleClick(option)">{{ option }}</button>
      </li>
    </ul>
    <button :class="{ down: showDropdown }">
      <img src="@/images/icons/arrow-down.png" alt="Arrow down" />
    </button>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "AppSelector",
  props: {
    placeholder: String,
    options: Array,
    value: String,
  },
  setup(props, { emit }) {
    const showDropdown = ref(false);

    watch(() => showDropdown.value, () => {
        document.addEventListener('click', handleIfClickedOutside)
    })

    const handleIfClickedOutside = (event) => {
      if (showDropdown.value) {
        if (! event.target.closest('.selector')) {
          showDropdown.value = !showDropdown.value;

          document.removeEventListener('click', handleIfClickedOutside);
        }
      }
    }

    const handleClick = (v) => {
      emit("update-filter", v);
    };

    const setShowDropdown = () => {
      showDropdown.value = true;
    };
    const setShowClose = () => {
      showDropdown.value = false;
    };

    const handleBlur = (e) => {
      if (e.relatedTarget === null || e.relatedTarget?.type !== "submit") {
        setShowClose();
      }
    };

    return {
      handleBlur,
      setShowDropdown,
      setShowClose,
      showDropdown,
      handleClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.selector {
  position: relative;
  border: 0.01rem solid $white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  cursor: pointer;
  background-color: $black;

  input {
    width: 100%;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 2px 40px 2px 15px;
    height: 40px;
    z-index: 10;
    background-color: transparent;

    font-family: "Zpix";
    color: $white;
    font-size: 20px;

    @include RWD($mediumLaptop) {
      padding: 1px 40px 1px 15px;
      font-size: 14px;
    }

    @include RWD($tablet) {
      font-size: 14px;
      position: relative;
      top: -2px;
    }

    &::placeholder {
      font-family: "Zpix";
      color: $white;
      font-size: 20px;

      @include RWD($mediumLaptop) {
        font-size: 14px;
      }

      @include RWD($tablet) {
        font-size: 14px;
        position: relative;
        top: -2px;
      }
    }
  }

  & > button {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    font-size: 0;
    z-index: 0;

    img {
      width: 19px;
      height: 10px;
      transition: 0.3s transform;

      @include RWD($tablet) {
        width: 15px;
        height: 6px;
      }
    }

    &.down {
      z-index: 20;
      img {
        transform: rotate(180deg);
      }
    }
  }

  .panel {
    width: calc(100% + 2px);
    border: 0.01rem solid $white;
    background-color: $black;
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 15;
    list-style: none;
    padding: 0;
    cursor: pointer;

    & > li {
      & > button {
        width: 100%;
        text-align: left;
        font-size: 20px;
        font-family: "Zpix";
        color: $white;
        line-height: 45px;
        padding: 0 15px;

        @include RWD($mediumLaptop) {
          font-size: 14px;
        }

        @include RWD($tablet) {
          font-size: 14px;
          line-height: 14px;
          padding: 10px;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }

  ul {
    max-height: 50vh;
    overflow: scroll;
  }
}
</style>