<template>
  <div class="portfolio-page">
    <h2 class="page-title">PORTFOLIO</h2>
    <section class="page-content">
      <div class="selectors">
        <AppSelector
          :key="randomKey"
          placeholder="作品類別"
          :options="categoryOptions"
          @updateFilter="(v) => updateFilter('type', v)"
          :value="currentFilter.type === 'type' ? currentFilter.value : ''"
        />
        <AppSelector
          :key="randomKey"
          placeholder="老師"
          :options="advisorOptions"
          @updateFilter="(v) => updateFilter('advisor', v)"
          :value="currentFilter.type === 'advisor' ? currentFilter.value : ''"
        />
        <AppSelector
          :key="randomKey"
          placeholder="基地位置"
          :options="siteOptions"
          @updateFilter="(v) => updateFilter('site', v)"
          :value="currentFilter.type === 'site' ? currentFilter.value : ''"
        />
        <AppSelector
          :key="randomKey"
          placeholder="學生"
          :options="studentOptions"
          @updateFilter="(v) => updateFilter('student', v)"
          :value="currentFilter.type === 'student' ? currentFilter.value : ''"
        />
      </div>
      <div class="items">
        <PortfolioCard
          v-for="(p, index) in filteredData"
          :key="p.name + index"
          :workId="p.work_id"
          :coverImage="p.coverImage"
          :nameInMandarin="p.name"
          :nameInEnglish="p.name_en"
          :author="p.student"
        />
      </div>
    </section>
  </div>
</template>

<script>
import AppSelector from "@/components/atoms/AppSelector";
import PortfolioCard from "@/components/atoms/PortfolioCard";
import { mapGetters } from "vuex";

export default {
  name: "Portfolio",
  components: { AppSelector, PortfolioCard },
  computed: {
    ...mapGetters(["portfolios"]),
    filteredData() {
      const filter = this.currentFilter.type;
      const value = this.currentFilter.value;

      if (!filter) {
        return this.portfolios;
      }

      if (filter === "type") {
        this.$forceUpdate();
        return this.portfolios.filter((p) => p.category === value);
      }

      if (filter === "advisor") {
        return this.portfolios.filter((p) => p.student.advisor.name === value);
      }

      if (filter === "student") {
        return this.portfolios.filter((p) => p.student.name === value);
      }

      if (filter === "site") {
        return this.portfolios.filter((p) => p.site === value);
      }

      return this.portfolios;
    },
    categoryOptions () {
      return [...new Set(this.portfolios.map(o => o.category))]
    },
    siteOptions () {
      return [...new Set(this.portfolios.map(o => o.site))]
    },
    studentOptions () {
      return [...new Set(this.portfolios.map(o => o.student?.name))]
    },
    advisorOptions () {
      return [...new Set(this.portfolios.map(o => o.student?.advisor?.name))]
    }
  },
  data() {
    return {
      currentFilter: { type: null, value: null },
      // categoryOptions: [],
      // advisorOptions: [],
      // siteOptions: [],
      // studentOptions: [],
      randomKey: Math.random()
    };
  },
  async mounted () {
    this.setupOptions();
  },
  methods: {
    setupOptions() {
      // this.portfolios.forEach((p) => {
      //   if (!this.categoryOptions.includes(p.category))
      //     this.categoryOptions.push(p.category);
      //   if (!this.siteOptions.includes(p.site)) this.siteOptions.push(p.site);
      //   if (!this.studentOptions.includes(p.student.name))
      //     this.studentOptions.push(p.student.name);
      //   if (!this.advisorOptions.includes(p.student.advisor.name))
      //     this.advisorOptions.push(p.student.advisor.name);
      // });
    },
    updateFilter(type, value) {
      this.currentFilter = { type, value };

      // force update
      this.randomKey = Math.random()
    },
  },
};
</script>

<style lang="scss" scoped>
.portfolio-page {
  @include page;

  .page-title {
    @include page-title($white);
  }

  .page-content {
    display: flex;
    flex-direction: column;
    width: 93vw;
    margin: 112px auto 0;

    @include RWD($tablet) {
      width: 100%;
      padding: 0;
      margin: 20px 0 0;
    }

    .selectors {
      display: flex;
      width: 75%;
      align-self: center;
      justify-content: center;
      gap: 50px;

      @include RWD($tablet) {
        display: block;
        width: 100%;
        padding: 0 25px;
      }

      & > div {
        &:first-child {
          flex: 0 0 36%;
        }

        &:nth-child(2) {
          flex: 0 0 24%;
        }

        &:nth-child(3),
        &:nth-child(4) {
          flex: 0 0 20%;
        }

        @include RWD($tablet) {
          & + div {
            margin-top: 10px;
          }
        }
      }
    }

    .items {
      margin-top: 135px;
      display: flex;
      flex-wrap: wrap;

      & > div {
        flex: 0 0 calc((100% - 105px) / 4);
        margin-left: 35px;
        margin-bottom: 60px;

        &:nth-child(4n + 1) {
          margin-left: 0;
        }
      }

      @include RWD($tablet) {
        display: block;
        margin-top: 30px;

        & > div {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
